<template>
  <div>
     <el-table :data="tableData" v-loading="loading" style="width: 100%"
    max-height="700" border >
      <el-table-column
        prop="miId"
        label="申请ID">
      </el-table-column>

      <el-table-column
        prop="keyword"
        label="密令关键词">
      </el-table-column>

       <el-table-column
        prop="themeName"
        label="密令主题">
      </el-table-column>

      <el-table-column
        prop="promotionId"
        label="推广位">
      </el-table-column>

      <el-table-column
        prop="giveNum"
        label="京享红包发放量（T+1）">
      </el-table-column>

      <el-table-column
        prop="useNum"
        label="京享红包使用量（T+1）">
      </el-table-column>

      <el-table-column
        prop="clickNum"
        label="点击量">
      </el-table-column>

      <el-table-column
        prop="orderNum"
        label="有效订单量">
      </el-table-column>

      <el-table-column
        prop="ygGmv"
        label="有效订单金额">
      </el-table-column>

      <el-table-column
        prop="ygFee"
        label="预估收入">
      </el-table-column>

      <el-table-column
        prop="startTime"
        label="开始时间">
      </el-table-column>

      <el-table-column
        prop="endTime"
        label="结束时间">
      </el-table-column>

<el-table-column label="操作" align="center" width="380">
          <template slot-scope="scope">
              <span class="handel" @click="getSecretHistory(scope.row)">查看密令词历史数据</span>
          </template>
        </el-table-column>
    </el-table>
     <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>
<script>
import { getThemeStat } from "@/api/lgsecret";
import router from '@/router';
  export default {
    
    data() {
      return {
      themeId: '',
      tableData: [],
      currentPage: 1,
      pageSize: 20,
      total: 0,
      loading: false,
      };
    },
    mounted(){
this.search(this.$route.params.themeId)
    },
    methods: {
      async search(val){
           this.loading = true;
        await getThemeStat({themeId: val})
          .then((res) => {
            this.loading = false;
            if (res.code == 1000) {
      this.tableData = res.data;
      this.total = res.data.total;
          }else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      })
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.search(); // 重新搜索以更新分页数据
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.search(); // 重新搜索以更新分页数据
    },
     getSecretHistory(val) {
      let routeData = router.resolve({ name: `灵感密令词历史`,　　　　
        query: {
          miId: val.miId,
          keyword:val.keyword
        } }) 
         window.open(routeData.href, '_blank');
    },
    }
  };
</script>

